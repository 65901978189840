<template>
    <div class="card">
        <div class="card">
            <div class="card-body text-center">
                <h1>Relatorio Cheques Emitidos</h1>
            </div>
        </div>
        <div class="accordion" role="tablist">
            <form role="form" class="form-horizontal">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.pedido-dados-gerais variant="light">Situação - (Clique para
                            Visualizar)</b-button>
                    </b-card-header>
                    <!-- visible -- coloca esse comando no b-colapse se quiser que já fique aberto -->
                    <b-collapse id="pedido-dados-gerais" accordion="pedido-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo1" name="ativo" value="-1">
                                                001-Custodia de Empresa
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo2" name="ativo" value="-2"
                                                unchecked-value="0">
                                                003-Compensado
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo3" name="ativo" value="-3"
                                                unchecked-value="0">
                                                005-cancelado
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo4" name="ativo" value="-4"
                                                unchecked-value="0">
                                                007-Restituido
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo5" name="ativo" value="-5"
                                                unchecked-value="0">
                                                009-Custodia Bancaria
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo6" name="ativo" value="-6"
                                                unchecked-value="0">
                                                002-Depositado
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo7" name="ativo" value="-7"
                                                unchecked-value="0">
                                                004-Devolvido
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo8" name="ativo" value="-8"
                                                unchecked-value="0">
                                                006-Reapresentado
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo9" name="ativo" value="-9"
                                                unchecked-value="0">
                                                008-Negociado
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <label for="produto_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo10" name="ativo" value="-10"
                                                unchecked-value="0">
                                                010-Inegociavel
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>

                    <!-- separa -->
                    <!-- visible -- coloca esse comando no b-colapse se quiser que já fique aberto -->
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.valores-informados variant="light">Vencimento - (Clique para
                            Visualizar)</b-button>
                    </b-card-header>
                    <b-collapse id="valores-informados" accordion="valores-informados" role="tabpanel">
                        <b-card-body>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-3" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">De:</label>
                                        <div class="col">
                                            <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                                                placeholder="dd/mm/aaaa hh:mm" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Até:</label>
                                        <div class="col">
                                            <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                                                placeholder="dd/mm/aaaa hh:mm" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </form>
        </div>
        <!--Engloba todos -->
        <div class="card-body" id="top">
            <div class="col-md-12 mt-10">
                <button class="btn btn-light" id="butt">
                    Visualizar
                </button>
            </div>
        </div>
    </div>
</template>
<script>
//import Multiselect from "vue-multiselect";
export default {
    data() {
        return {
            nome: 'ola',
            hideSearches: false,
            centtros: [
                'centros1',
                'centros2',
                'centros3',
            ],
            fornecedores: [
                'fornecedor1',
                'fornecedor2',
                'fornecedor3',
            ]
        }
    },
   // components: { Multiselect },
    computed: {
        isHide() {
            return (!this.inserindo && !this.editando);
        },
    },
    methods: {
        // geters para preencher os vetotes do multiselct 
        get_fornecedores() {

        },
        get_centro_resultados() {

        },
        get_centro_custo() {

        },
        // -----------------------------------------
        doPost() {

        },
        searchInputs() {
            this.hideSearches = !this.hideSearches;
        },
    }

}
</script>
  
<style></style>